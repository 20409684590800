import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import host from "../../host";
import React, { useState, useEffect } from "react";

export default function HeaderPage() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <header className={isScrolled ? "fixed" : ""}>
      <div className="container">
        <div className="inner-header ">
          <div className="logo-box  ">
            <div className="logo  ">
              <Link to="/" className="navbar-brand">
                <img src={logo} />
              </Link>
            </div>
          </div>
          <nav className="navbar menu-bar  navbar-expand-lg    ">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                {/* <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="#">How it works</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="#">Features</Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/plan">
                    Plans
                  </Link>
                </li>
                {/* <li className="nav-item">
        <Link className="nav-link" to="#">Customer's</Link>
      </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/about-us">
                    About us
                  </Link>
                </li>
              </ul>
            </div>
          </nav>

          <div className="col-buttons">
            <Link
              to={
                host.selectright_url + "/login" +
                "?redirectUrl=" +
                host.selectright_url +
                "/dashboard&loginUrl=" +
                host.selectright_url +
                "/signin"
              }
            >
              Login
            </Link>
            <a href={host.selectright_url + "/signup"}>
              {" "}
              <button
                className="btn  btn-blue"
                type="button"
                onClick={() => (window.location.href = "/book-demo")}
              >
                {" "}
                Start Hiring For Free
              </button>
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="bars">
              <span className="top" />
              <span className="middle" />
              <span className="bottom" />
            </span>
          </button>
        </div>
      </div>
    </header>
  );
}
