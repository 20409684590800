import React, { useState, useEffect } from 'react';
import { Link, useRoutes } from 'react-router-dom';
import Lottie from "lottie-react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import bookdemo from "../assets/images/bookdemo.png";
import Screening from "../assets/json/AI-Application-Received.json";
import fake from "../assets/json/fake.json";
import Single from "../assets/json/allplateform.json";
import Eliminate from "../assets/images/AI-passive-candidate-sourcing.gif";
import Better from "../assets/json/MIS.json";
import savetime from "../assets/images/real-time.gif"
import host from '../host';

import DigiLocker from "../assets/images/DigiLocker.png"
import oneclick from "../assets/images/One.png"
import ImageMatching from "../assets/images/ImageAcross.png"
import AIMl from "../assets/images/AIML.png"
import Assistive from "../assets/images/Assistive.png"
import IdentityVerification from "../assets/images/IdentityVerification.png"
import Actionable from "../assets/images/Actionable.png"
import eeautomation from "../assets/images/EndtoEnd.png"
import Scaling from "../assets/images/AutoScaliing.png"
import sr from "../assets/images/Intelligent.png"
import interview from "../assets/images/IntegratedInterview.png"

import cv from "../assets/images/InstantAI.png"



import CheckIcon from '@mui/icons-material/Check';
import ReCAPTCHA from "react-google-recaptcha";
import { TextField, Select, MenuItem, Button, Modal, Box, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import { Phone } from '@mui/icons-material';



export const BookDemo = () => {
   
   const defaultOptions = {
      loop: true,
      autoplay: true,
   };
   const commonpopup = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
   };
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => {
      setOpen(false);
    };
   const [captchaText, setCaptchaText] = useState('');
   const [userInput, setUserInput] = useState('');
   const [isValid, setIsValid] = useState(false);
   const generateCaptcha = () => {
      const captcha = Math.random().toString(36).slice(2, 8).toUpperCase();
      setCaptchaText(captcha);
      setUserInput('');
      setIsValid(false);
   };
   const handleInputChange = (event) => {
      setUserInput(event.target.value);
   };
   const handleSubmit = (event) => {
      event.preventDefault();
      if (userInput === captchaText) {
         setIsValid(true);
         // Perform actions for successful validation here
      } else {
         setIsValid(false);
         // Perform actions for failed validation here
      }
   };
   function onChange(value) {
      setIsValid(true)
   }

   const [name, setName] = useState('')
   const [email, setEmail] = useState('')
   const [phone, setPhone] = useState('')
   const [org_name, setOrgName] = useState('')
   const [last_name, setLastName] = useState('')
   const [countryCode, setCountryCode] = useState('IN')
   const [functionFor, setFunctionFor] = useState('Talent Acquisition')
   const [responsibility, setResponsibility] = useState('Executive (C-Suite)')
   const [number_of_employees, setNumberOfEmployees] = useState("1-99")
   const [registerFor, setRegisterFor] = useState("Demo")
   const [hq, setHq] = useState('')
   const [message, setMessage] = useState('')
   const [loading, setLoading] = useState(false)
   const [demo, setDemo] = useState(true)
   const [trial, setTrial] = useState(true)


   const validateEmail = (email) => {
      return String(email)
         .toLowerCase()
         .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         );
   };

   const bookAdemo = () => {
      let trialmessage = '';
      console.log(countryCode)
      if (demo) {
         trialmessage += "Demo "
      }
      if (trial) {
         trialmessage += "Trial"
      }
      if (!trial && !demo) {
         trialmessage += "Nothing"
      }
      if (name == '') {
         setMessage('Please provide valid name')
      }
      else if (phone == '') {
         setMessage('Please provide valid phone')
      }else if (phone.length!=13 && countryCode=='IN')  {
         setMessage('Please provide valid phone')
      }
      else if (phone.length < 7 || phone.length > 16)  {
         setMessage('Please provide valid phone')
      }
      else if (!validateEmail(email) || email.length < 1) {
         setMessage('Please provide valid email')
         //   setShowsnackbar(true)
      } else if (email.indexOf('gmail.com') != -1) {
         setMessage('Email address should be business email')
      } else if (org_name == '') {
         setMessage('Please provide valid org name')
      }
      else if (last_name == '') {
         setMessage('Please provide valid last name')
      }
      // else  if(countryCode==''||countryCode.length<2){
      //    setMessage('Please provide valid country code')
      // }
      // else  if(functionFor==''){
      //    setMessage('Please provide valid function')
      // }
      // else  if(responsibility==''){
      //    setMessage('Please provide valid responsibility')
      // }
      else if (hq == '') {
         setMessage('Please provide valid headquarter')
      }
      // else  if(number_of_employees==''){
      //    setMessage('Please provide valid number of employees')
      // }
      else {
         setMessage('')
         setLoading(true)
         fetch(process.env.REACT_APP_SSO_API_URL + '/api/v1/public/book_demo', {
            method: "POST",
            headers: {
               "Content-type": "application/json"
            },
            body: JSON.stringify({
               first_name: name,
               email: email,
               phone: phone,
               org_name: org_name,
               last_name: last_name,
               country_code: countryCode,
               function_for: functionFor,
               responsibility: responsibility,
               number_of_employees: number_of_employees,
               hq_location: hq,
               is_trial: trialmessage
            })
         }).then((res) => {
            return res.json()
         }).then(async (data) => {
            setLoading(false)
            if (data.success) {
               //  setMessage("Success! Thanks for registering")
               //  setShowsnackbar(true)
               handleOpen()
               setName('')
               setEmail('')
               setOrgName('')
               setLastName('')
               setHq('')
               setPhone('')
            }
            else {
               setMessage(data.message)
               //  setShowsnackbar(true)
            }
         }).catch((err) => {
            setLoading(false)
            console.log(err)
         })
      }
   }
   useEffect(() => {
      document.title = 'Book-demo';
   }, []);

  
   const [value, setValue] = useState()
   return (
      <div>
         <HeaderPage />
         <section className="book-demo">
            <div className="containe p-0">
               <div className="row g-0">
                  <div className="col-xxl-7 col-lg-7 col-md-7">
                     <div className="bookdemoauth-bg    ">
                        <div className="row justify-content-center align-items-center">
                           <div className="col-xl-12">
                              <div className="bookdemo-title" data-aos="fade-up" data-aos-duration="1000">
                                 <h3>SelectRight.AI: AI-POWERED CANDIDATE SELECTOR</h3>
                              </div>
                              <div className="bookdemo-image" data-aos="fade-up" data-aos-duration="2000">
                                 <img src={bookdemo} />
                              </div>
                              <div className="registernowbtn text-center" data-aos="fade-up" data-aos-duration="3000">
                                 <a href="#registerdemoform" className="btn btn-white">Register Here</a>

                              </div>
                              <div className="bookdemo-point">


                                 <div className="pointsec" data-aos="fade-right" data-aos-duration="3000" >
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={oneclick} />

                                       </div>
                                       <div className="point-title">
                                          <p>One-Click Job Posting </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={cv} />


                                       </div>
                                       <div className="point-title">
                                          <p>Instant AI-Powered CV Parsing <br></br> & Analysis
                                          </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={interview} />

                                       </div>
                                       <div className="point-title">
                                          <p> Integrated Interview Scheduling</p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={sr} />

                                       </div>
                                       <div className="point-title">
                                          <p> Intelligent Selection & Rejection </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={Scaling} />

                                       </div>
                                       <div className="point-title">
                                          <p> Auto Scaling Tech To Support All Loads
                                          </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={eeautomation} />


                                       </div>
                                       <div className="point-title">
                                          <p> End-to-End Automation
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="pointsec" data-aos="fade-left" data-aos-duration="2000">
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={Actionable} />

                                       </div>
                                       <div className="point-title">
                                          <p> Actionable One-Window Candidate View
                                          </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={IdentityVerification} />

                                       </div>
                                       <div className="point-title">
                                          <p>  Identity Verification with Trust DBs <br></br>for Fraud Detection</p>

                                          <img src={DigiLocker} />
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={AIMl} />
                                       </div>
                                       <div className="point-title">
                                          <p>AI & ML Fine Tuned For You </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={Assistive} />



                                       </div>
                                       <div className="point-title">
                                          <p> Assistive AI Analysis Based on Priority
                                          </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <img src={ImageMatching} />

                                       </div>
                                       <div className="point-title">
                                          <p> Image Matching Across Stages
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="globlepointsec-wrapper" data-aos="fade-up" data-aos-duration="2000">
                                 <h6>Global Candidate AI Score</h6>
                                 <div className="globlepointsec">
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <CheckIcon />
                                       </div>
                                       <div className="point-title">
                                          <p>CV Score </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <CheckIcon />
                                       </div>
                                       <div className="point-title">
                                          <p>Assessment Score
                                          </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <CheckIcon />
                                       </div>
                                       <div className="point-title">
                                          <p>ID Match Score
                                          </p>
                                       </div>
                                    </div>
                                    <div className="pointsec-row">
                                       <div className="point-icon">
                                          <CheckIcon />
                                       </div>
                                       <div className="point-title">
                                          <p>Interview Score
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-5 col-lg-5 col-md-5" id="registerdemoform">
                     <div className="bookdemoauth-full-page-content  " data-aos="fade-up" data-aos-duration="1000">
                        <div className="auth-content   ">
                           <div className="welcometext text-center">
                              <div className=" section-title text-center ">
                                 <h2 className="mb-0"> Register Here </h2>
                                 <div className="col-md-12 text-center mt-2 " style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                       <input checked={demo} onClick={() => { setDemo((e) => { return !e }) }} type="checkbox" style={{ margin: 10, fontSize: 20, width: 16, height: 16 }} />
                                       <label style={{ fontSize: 16 }}> Demo</label>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                       <input checked={trial} onClick={() => { setTrial((e) => { return !e }) }} type="checkbox" style={{ margin: 10, fontSize: 20, width: 16, height: 16 }} />
                                       <label style={{ fontSize: 16 }}> Trial</label>
                                    </div>

                                 </div>
                                 <div className="col-md-12 text-center mt mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                    <span className="free-trial">Free 30-Days Trial, No Commitment </span>

                                 </div>
                              </div>
                           </div>
                           <form >
                              <div className="row">
                                 

                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label">First Name <span className="text-danger">*</span></label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <TextField
                                             type="text"
                                             value={name}
                                             onChange={(e) => { setName(e.target.value) }}
                                             placeholder='Enter first name '
                                          />
                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <TextField
                                             type="text"
                                             value={last_name}
                                             onChange={(e) => { setLastName(e.target.value) }}
                                             placeholder='Enter last name '
                                          />
                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label"> Business Email <span className="text-danger">*</span> </label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <TextField
                                             type="text"
                                             value={email}
                                             onChange={(e) => { setEmail(e.target.value) }}
                                             placeholder='Enter business email'
                                          />
                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label"> Headquarters Location <span className="text-danger">*</span> </label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <TextField
                                             type="text"
                                             value={hq}
                                             onChange={(e) => { setHq(e.target.value) }}
                                             placeholder='Enter business location'
                                          />
                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <PhoneInput
                                             international
                                             countryCallingCodeEditable={false}
                                             value={phone}
                                             onChange={(e) => { setPhone(e) }}
                                             defaultCountry="IN"
                                             onCountryChange={(e) => { setCountryCode(e) }}
                                             className="worldwidephone" />

                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label">Organisation Name <span className="text-danger">*</span> </label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <TextField
                                             type="text"
                                             value={org_name}
                                             onChange={(e) => { setOrgName(e.target.value) }}
                                             placeholder='Enter organisation name'
                                          />
                                       </FormControl>
                                    </div>
                                 </div>

                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label">Function </label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <Select
                                             placeholder='Select City'
                                             value={functionFor}
                                             onChange={(e) => { setFunctionFor(e.target.value) }}
                                          >
                                             <MenuItem value={"Talent Acquisition"} > Talent Acquisition</MenuItem>
                                             <MenuItem value={"Human Resources"} > Human Resources</MenuItem>
                                             <MenuItem value={"Talent Management"}  >Talent Management</MenuItem>
                                             <MenuItem value={"Recruitment Marketing"}  >Recruitment Marketing  </MenuItem>
                                             <MenuItem value={"IT or HR Ops"}  >IT or HR Ops</MenuItem>
                                             <MenuItem value={"Learning & Development"} >  Learning & Development</MenuItem>
                                             <MenuItem value={"Belonging & Diversity"} >  Belonging & Diversity</MenuItem>
                                             <MenuItem value={"Legal & Procurement"}  >Legal & Procurement</MenuItem>
                                             <MenuItem value={"General & Admin"} >  General & Admin</MenuItem>
                                             <MenuItem value={"Partner"} >  Partner </MenuItem>
                                             <MenuItem value={"Industry Analyst"} >  Industry Analyst</MenuItem>
                                             <MenuItem value={"Other"}>Other</MenuItem>
                                          </Select>
                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label"> Responsibility Level </label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <Select
                                             placeholder='Select City'
                                             value={responsibility}
                                             onChange={(e) => { setResponsibility(e.target.value) }}
                                          >
                                             <MenuItem value={"Executive (C-Suite)"} > Executive (C-Suite)</MenuItem>
                                             <MenuItem value={"Vice President"}  > Vice President</MenuItem>
                                             <MenuItem value={" Director"} >  Director</MenuItem>
                                             <MenuItem value={"Manager"}  > Manager  </MenuItem>
                                             <MenuItem value={"Associate (Recruiter)"} >   Associate (Recruiter)</MenuItem>
                                             <MenuItem value={"Consultant"}  >Consultant</MenuItem>
                                             <MenuItem value={"Administrative"} >   Administrative</MenuItem>
                                          </Select>
                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="form-group">
                                       <label className="form-label"> # of Employees </label>
                                       <FormControl fullWidth className=" bg-white ">
                                          <Select
                                             placeholder='Select City'
                                             value={number_of_employees}
                                             onChange={(e) => { setNumberOfEmployees(e.target.value) }}
                                          >
                                             <MenuItem value={"1-99"} >  1-99</MenuItem>
                                             <MenuItem value={"100-1000"}  >  100-1000</MenuItem>
                                             <MenuItem value={"1000-5000"} >  1000-5000</MenuItem>
                                             <MenuItem value={"5000+"}  >  5000+ </MenuItem>
                                          </Select>
                                       </FormControl>
                                    </div>
                                 </div>
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="form-group" >
                                       <ReCAPTCHA
                                          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}

                                          onChange={onChange}
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-12 text-center mt-3">
                                    <button className="btn btn-blue   " type="button" disabled={!isValid || loading} onClick={bookAdemo}  >{loading ? "Loading..." : "Submit"}</button>
                                 </div>
                              </div>
                           </form>
                           <div style={{ display: "flex", width: 100 + "%", justifyContent: "center", alignItems: "center", color: "red", margin: 10 }}>{message}</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="section-pedding">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">
                     <div className="section-title" data-aos="fade-up" >
                        <h2 className>Streamlined recruitment process</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
                     <div className="feature-tab-info card " data-aos="fade-right" data-aos-duration="1000">
                        <div className="feature-tab-head">
                           <Lottie animationData={Screening} loop={true} className="ctalottie" />
                        </div>
                        <div className="feature-tab-body">
                           <h3> Screening every applicant made possible with the automated system</h3>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
                     <div className="feature-tab-info card  " data-aos="fade-up" data-aos-duration="2000" >
                        <div className="feature-tab-head">
                           <Lottie animationData={fake} loop={true} className="ctalottie" />
                        </div>
                        <div className="feature-tab-body">
                           <h3>No more fake hirings – Enabled through state of art AI enabled proctoring</h3>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
                     <div className="feature-tab-info card " data-aos="fade-left" data-aos-duration="3000">
                        <div className="feature-tab-head" >
                           <Lottie animationData={Single} loop={true} className="ctalottie" />
                        </div>
                        <div className="feature-tab-body">
                           <h3>  Single window applicants tracking – Track all applicants from across all platforms in one ATS</h3>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
                     <div className="feature-tab-info card " data-aos="fade-right" data-aos-duration="2000">
                        <div className="feature-tab-head">
                           <img src={Eliminate} />
                        </div>
                        <div className="feature-tab-body">
                           <h3>Eliminate human judgement error – No more missing good candidate and inclusion of unfit candidate</h3>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
                     <div className="feature-tab-info card  " data-aos="fade-up" data-aos-duration="3000">
                        <div className="feature-tab-head">
                           <img src={savetime} />
                        </div>
                        <div className="feature-tab-body">
                           <h3>Saving more than  80% of the recruitment time</h3>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
                     <div className="feature-tab-info card  " data-aos="fade-left" data-aos-duration="4000">
                        <div className="feature-tab-head">
                           <Lottie animationData={Better} loop={true} className="ctalottie" />
                        </div>
                        <div className="feature-tab-body">
                           <h3>Better recruitment expense decisions enabled by MIS</h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box className="  modal-confirm modal-common   " sx={commonpopup} >
               <div className="modal-body">
                  <div className="icon-box">
                     <i className="las la-check-circle"></i>
                  </div>
                  <h4 className="modal-title w-100 text-center"> Success!</h4>
                  <div className="model-content text-center">
                     <h3>Thank You</h3>
                     <p> We will get in touch with you within 24 Hours  </p>
                  </div>
                  <div className="modal-btn">
                     <Link className="btn btn-success btn-block  " to="/" onClick={handleClose} >Ok</Link>
                  </div>
               </div>
            </Box>
         </Modal>
         <FooterPage />
      </div>
   )
}
